<template>
  <div class="head-container">
    <el-select
        v-model="lang"
        clearable
        placeholder="语言"
        class="filter-item"
        style="width: 130px"
        @change="queryHandle"
    >
      <el-option
          v-for="item in langOptions"
          :key="item.key"
          :label="item.display_name"
          :value="item.key"
      />
    </el-select>
    <el-select
      v-model="dialogType"
      clearable
      placeholder="弹窗形式"
      class="filter-item"
      style="width: 130px"
      @change="queryHandle"
    >
      <el-option
        v-for="item in queryTypeOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-select
        v-model="listType"
        clearable
        placeholder="列表形式"
        class="filter-item"
        style="width: 130px"
        @change="queryHandle"
    >
      <el-option
          v-for="item in listTypeOptions"
          :key="item.key"
          :label="item.display_name"
          :value="item.key"
      />
    </el-select>
    <el-input
      v-model="theme"
      style="width: 130px"
      clearable
      placeholder="主题"
      class="filter-item"
      @clear="queryHandle"
      @keyup.enter.native="queryHandle"
    ></el-input>
    <el-button
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="queryHandle">搜索</el-button>
    <el-button
      v-if="getPermission()"
      class="filter-item"
      size="mini" type="primary" icon="el-icon-plus" @click="addHandle">新增</el-button>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
export default {
  data() {
    return {
      lang: '',
      langOptions: [
        { key: 'zh_CN', display_name: '中文' },
        { key: 'zh_Hant', display_name: '繁体' },
        { key: 'en_US', display_name: '英文' },
      ],
      queryTypeOptions: [
        { key: 0, display_name: '不显示' },
        { key: 1, display_name: '显示' },
      ],

      dialogType: '',

      listType: '',
      listTypeOptions: [
        { key: 1, display_name: '富文本' },
        { key: 2, display_name: '外链' },
      ],
      theme: '',
    }
  },
  methods: {
    queryHandle(){
      this.$emit('queryHandle');
    },
    addHandle(){
      this.$emit('addHandle')
    },
    getPermission(){
      return checkPermission(['ADMIN','TPopUpNotice_ALL','TPopUpNotice_CREATE']);
    }
  }
}
</script>
