<template>
  <div class="app-container notice-dialog-list">
    <eHeader
        ref="header"
        @queryHandle="toQuery"
        @addHandle="addHandle"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="lang" label="语言">
        <template slot-scope="scope">
          <span>{{getLang(scope.row.lang)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"/>
      <el-table-column label="通知对象">
        <template slot-scope="scope">
          <span>{{scope.row.receiverType == 1 ? '客户': '代理'}}</span>
        </template>
      </el-table-column>

      <el-table-column
          label="弹窗形式"
      >
        <template slot-scope="scope">
          <span
              :class="{
                show: scope.row.status == 1
              }"
              class="show-btn-box">{{scope.row.status == 1 ? '显示': '不显示'}}</span>
        </template>
      </el-table-column>

      <el-table-column
          prop="target"
          label="内容形式"
      >
        <template slot-scope="scope">
          <span
              class="btn-box"
          >{{scope.row.contentType == 1 ? '富文本': '外链'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="更新时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.updateTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="getLookFlag()"
            @click="editHandle(scope.row)"
            size="mini"
            type="success"
          >编辑
          </el-button>
          <el-button
            v-if="getDeleteFlag()"
            @click="deleteHandle(scope.row)"
            size="mini"
            type="danger"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-dialog
      :is-add="isAdd"
      :dialog-show="dialogShow"
      :row-data="rowData"
      @refresh-list="refreshlist"
      @close-dialog="closeHandle"
    ></add-or-edit-dialog>
  </div>
</template>

<script>
  import { deleteNoticeDialog } from '@/api/notificationManager/noticeDialog'
  import addOrEditDialog from '@/components/notificationManager/noticeDialog/addOrEditDialog'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/notificationManager/noticeDialog/header'

  export default {
    name: 'noticeDialog',
    components: {
      eHeader,
      addOrEditDialog,
    },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        rowData: {},
        btnLoading: false,
        dialogShow: false,
        isAdd: false,
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      beforeInit() {
        this.url = 'crm/tPopUpNotice'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort,
        }
        let header = this.$refs.header;
        if(header.lang){
          this.params.lang = header.lang;
        }
        if(header.dialogType !== ''){
          this.params.status = header.dialogType;
        }
        if(header.listType){
          this.params.contentType = header.listType;
        }
        if(header.theme){
          this.params.title = header.theme;
        }
        return true;
      },
      getLookFlag() {
        return checkPermission(['ADMIN','TPublicNotice_ALL','TPublicNotice_EDIT']);
      },
      getDeleteFlag() {
        return checkPermission(['ADMIN','TPopUpNotice_ALL','TPopUpNotice_DELETE']);
      },
      getStatus(status){
        switch (status) {
          case 0:
            return '不显示';
          case 1:
            return '显示';
        }
      },
      editHandle(row){
        this.isAdd = false;
        this.rowData = row;
        this.dialogShow = true;
      },
      deleteHandle(row){
        this.$confirm(`是否要删除本条通知弹框`,'提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //点击确定的操作(调用接口)
          deleteNoticeDialog({id:row.id}).then(res => {
            this.init()
            this.$notify({
              title: '删除成功',
              type: 'success',
              duration: 1000
            })
          }).catch(err => {

          })
        }).catch(() => {
          //几点取消的提示
        });
      },
      closeHandle(){
        this.dialogShow = false;
      },
      addHandle(){
        this.isAdd = true;
        this.dialogShow = true;
      },
      refreshlist(){
        this.init();
      },
      getLang(lang){
        switch (lang){
          case 'zh_CN':
            return '中文简体';
          case 'en_US':
            return '英文';
          case 'zh_Hant':
            return '中文繁体';
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.notice-dialog-list {
  .show-btn-box {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    color: #909399;
    background-color: rgba(228,238,288,0.1);
    &.show {
      border: 1px solid #6AB687;
      color: #6AB687;
      background-color: rgba(106, 182, 135, 0.1);

    }
  }
  .btn-box {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #5D9EF8;
    border-radius: 4px;
    color: #5D9EF8;
    background-color: rgba(93, 158, 248, 0.1);
  }
}
</style>
