<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="isAdd ? '新增': '编辑' + '通知弹窗'"
    width="600px"
    class="add-or-edit-notice-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="80px"
    >
      <el-form-item prop="lang" label="语言">
        <el-radio v-model="form.lang" label="zh_CN">中文简体</el-radio>
        <el-radio v-model="form.lang" label="zh_Hant">中文繁体</el-radio>
        <el-radio v-model="form.lang" label="en_US">EngLish</el-radio>
      </el-form-item>
      <el-form-item prop="title" label="标题">
        <el-input
          placeholder="请输入"
          v-model="form.title"
          :maxlength="titleWorldsCount"
          :show-word-limit="true"
        />
      </el-form-item>
      <el-form-item label="通知对象" prop="receiverType">
        <el-radio
            v-model="form.receiverType"
            :label="1"
        >客户</el-radio>
        <el-radio
            v-model="form.receiverType"
            :label="2"
        >代理</el-radio>
      </el-form-item>

      <el-form-item
          label="弹窗形式"
          prop="status"
      >
        <el-radio
            v-model="form.status"
            :label="1"
        >显示</el-radio>
        <el-radio
            v-model="form.status"
            :label="0"
        >不显示</el-radio>
      </el-form-item>
<!--      -->
      <el-form-item
          v-if="form.status == 1"
          label="有效期"
      >
        <el-row :gutter="10">
          <el-col :span="5">
            <el-date-picker
              style="width: 140px"
              v-model="today"
              type="date"
              :disabled="true"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2" :offset="3">
            <div>至</div>
          </el-col>
          <el-col :span="10">
            <el-form-item
                v-if="form.status == 1"
                prop="endTime"
            >
              <el-date-picker
                v-model="form.endTime"
                type="date"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        ref="qrpic"
        label="列表封面"
        prop="coverImgUrl"
      >
        <el-upload
            class="upload-demo"
            action="''"
            :accept="getLimitUploadImageFormat()"
            :http-request="handleSuccessQrPic"
            :before-upload="beforeAvatarUpload"
            list-type="picture"
            :show-file-list='false'
        >
          <!--:on-success="handleSuccessQrPic"-->
          <img v-if="form.coverImgUrl" :src="form.coverImgUrl" class="qr-pic">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <uploadImgTips></uploadImgTips>
      </el-form-item>

      <el-form-item label="内容形式" prop="contentType">
        <el-radio
            v-model="form.contentType"
            :label="1"
        >富文本</el-radio>
        <el-radio
            v-model="form.contentType"
            :label="2"
        >外链</el-radio>
      </el-form-item>
      <el-form-item
          v-if="form.contentType == 1"
          prop="content"
      >
        <quill-editor
          @catchData="catchData"
          :defaultValues="form.content"
        ></quill-editor>
      </el-form-item>
      <el-form-item
          v-if="form.contentType == 2"
          prop="outLink"
      >
        <el-input
          placeholder="请输入"
          v-model="form.outLink"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click.stop="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { base64toFile, getBase64, getLimitUploadImageFormat, limitImageSizeFormat } from '@/utils/index'
  import quillEditor from '@/components/tools/quillEditor'
  import { parseTime } from '../../../utils'
  import { addNoticeDialog,editNoticeDialog } from '@/api/notificationManager/noticeDialog'
  import { uploadFile } from '@/api/data'
export default {
    components:{
      quillEditor,
    },
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    dialogShow:{
      type: Boolean,
      default: false
    },
    rowData:{
      type: Object,
      default(){
        return {
          outLink: '',
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: '',
        lang: 'zh_CN',
        title: '',
        receiverType: 1,
        status: 1,
        endTime: '',
        coverImgUrl: '',
        contentType: 1,

        content: '',
        outLink: '',
      },

      rules:{
        title:[
          { required: true, message: '请填写标题', trigger: 'blur' },
        ],
        endTime:[
          { required: true, message: '请选择结束日期', trigger: 'change' },
        ],
        content:[
          { required: true, message: '请输入内容', },
        ],
        outLink:[
          { required: true, message: '请输入', trigger: 'blur'},
        ],
      },
    }
  },
  watch:{
    rowData:{
      handler(newValue){

        if(Object.keys(newValue).length > 0 && this.isAdd == false){
          this.assignmentFormCom(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    dialogShow(newValue){
      this.$nextTick(() => {
        if(newValue && this.isAdd){
          this.clearForm();
          this.$refs.form && this.$refs.form.clearValidate();
        }
        if(newValue && !this.isAdd){
          this.assignmentFormCom(this.rowData);
          this.$refs.form && this.$refs.form.clearValidate();
        }
      });
    },
  },
  computed: {
    typeDisabled(){
      return !this.isAdd ? true : false;
    },
    today(){
      let now = new Date();
      return parseTime(now).split(' ')[0];
    },
    titleWorldsCount(){
      if(this.form.lang == 'zh_CN'){
         return 20;
      }else {
        return 60;
      }
    }
  },
  methods: {
    getLimitUploadImageFormat,
    channelNameEnInputHandle(){
      this.form.nameEn = limitEnAndSpace(this.form.nameEn);
    },
    clearForm(){
      this.form = {
        id: '',
        lang: 'zh_CN',
        title: '',
        receiverType: 1,
        status: 1,
        endTime: '',
        coverImgUrl: '',

        contentType: 1,
        content: '',
        outLink: '',
      };
    },
    cancel() {
      this.close();
    },
    close(){
      this.$emit('close-dialog');
    },

    beforeAvatarUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    handleSuccessQrPic(item){
      this.$refs.qrpic.clearValidate();
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.coverImgUrl = res.data.content[0].url;
        });
      });
    },

    beforeCloseHandle(){
      this.close();
    },
    assignmentFormCom(data){
      data.outLink = '';
      this.form = JSON.parse(JSON.stringify(data));
      if(data.contentType == 2){
        this.form.outLink = this.form.content;
        this.form.content = '';

      }
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    doAdd() {
      let params = {
        title: this.form.title,
        lang: this.form.lang,
        receiverType: this.form.receiverType,
        startTime: (new Date(this.today)).getTime(),

        coverImgUrl: this.form.coverImgUrl,
        contentType: this.form.contentType,
        status: this.form.status,
      };
      if(this.form.status){
        params.endTime = this.form.endTime.getTime();
      }else {
        //公告不显示,结束日期就默认为第二天 (24 * 60 * 60 * 1000)
        params.endTime = params.startTime + (24 * 60 * 60 * 1000);
      }

      if(this.form.contentType == 1){
        params.content = this.form.content;
      }else {
        params.content = this.form.outLink;
      }
      this.loading = true;
      addNoticeDialog(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        });
        this.loading = false
        this.refresh();
        this.close();
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    doEdit() {
      let params = {
        id: this.form.id,
        title: this.form.title,
        lang: this.form.lang,
        receiverType: this.form.receiverType,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        coverImgUrl: this.form.coverImgUrl,
        contentType: this.form.contentType,

        status: this.form.status,
      }
      if(this.form.contentType == 1){
        params.content = this.form.content;
      }else {
        params.content = this.form.outLink;
      }

      this.loading = true;
      editNoticeDialog(params).then(res => {

        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.refresh();
        this.close();
        this.resetForm();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.$refs['form'].clearValidate();
      this.$refs['form'].resetFields();
    },
    catchData(value) {
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.content = value
    },
    refresh(){
      this.$emit('refresh-list');
    }
  }
}
</script>
<style lang="less" scoped>
.add-or-edit-notice-dialog {
  .money-prefix {
    width: 80px;
  }
  .time-box {
    width: 100%;
  }
  .time-spilt {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .input-box-rate {
    width: 380px;
    position: relative;
    .ratesuffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }
  .upload-img {
    width: 50px;
    height: 50px;
  }
  .uppload-btn {
    color: skyblue;
  }
  .el-form-item__label {
    word-break: break-all;
  }
  .delete {
    cursor: pointer;
  }
  .icon-class {
    width: 100px;
    margin-top: 20px;
  }

  .avatar-uploader-icon {
    width: 200px;
    height: 90px;
    border: 1px solid #DDDFE6;
    line-height: 90px;
    text-align: center;
  }
  .qr-pic {
    width: 200px;
    height: 90px;
  }
}
</style>
